@import 'config/fonts';

header {
    border-bottom: 2px solid #FFBA97;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
    height: 44px;
    background-color: white;

    @include for-landscape-mobile {
        display: none;
    }
    
    // create sticky navbar for ipad ontop
    @include for-tablet-portrait-up {
        position: fixed;
        top:0;
        left: 0;
        right: 0;
        width: 100%;
        height: 56.3px;
        z-index: 1000;
    }

    .viewport {
        margin: 0 auto;
        padding: 0 20px;

        
        @include for-tablet-portrait-up {  
            padding: unset;  
            max-width: 760px;
    
            &>.inner {
                padding: 0 45px;
            }
        }
    
        @include for-tablet-landscape-up {    
            max-width: 829px;
    
            &>.inner {
                padding: 0 65px;
            }
        }
   
        nav {
            display: block;
    
            ul {
                padding: 0;
                margin: 0;
                display: flex;

                @include for-tablet-portrait-up {
                    justify-content: center;
                }

                li {

                    span.text {
                        display: inline-flex;
                        justify-content: center;
                        align-items: center;
                        text-align: initial;
                        margin: auto;
                        height: 44px;
                        font-family: $header_font_style;
                        font-size: 14px;
    
                        @include for-tablet-portrait-up {
                            font-size: 15px;
                            height: 56.3px;
                        }

                        @include for-ipad-portrait-up {
                            font-size: 18px;
                        }
                    }
                    
                    // show circle
                    span.num-circle {
                        height: 20px;
                        width: 20px;
                        min-height: 20px;
                        min-width: 20px;
                        border-radius: 50%;
                        border:1px solid #B8B8B8;
                        color:#B8B8B8;
                        background:transparent;
                        text-align:center;
                        margin-left: 0;
                        margin-right: auto;
                        left: -10px;
                        font-size: 14px;
                        font-family: "Abhaya Libre", serif;
    
                        @include for-tablet-portrait-up {
                            margin-right: initial;
                            font-size: 14px;
                            height: 19px;
                            width: 19px;
                            min-height: 19px;
                            min-width: 19px;
                        }

                        @include for-ipad-portrait-up {
                            height: 20px;
                            width: 20px;
                            min-height: 20px;
                            min-width: 20px;
                        }
                    }

                }
                
                .prev-nav-container {
                    overflow: hidden;
                    max-width: 100vw;
                    
                    &.show {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        transition: max-width 1s;
                        
                        @include for-tablet-portrait-up {
                            display: none;
                        }

                        &.read-carefully {
                            flex-grow: 1;

                            .show-prev.show:after {
                                right: -10px;

                                @include for-iphone678 {
                                    right: -25px;
                                }

                            }
                            
                            .show-prev.fill-info.show {
                                @include for-iphone678 {
                                    padding-right: 15px;
                                }
                                @include for-iphone678plus {
                                    padding-right: 25px;
                                }
                            }
                        }

                        &.sign {
                            flex-grow: 0.5;

                            .show-prev.show:after {
                                right: -10px;

                                @include for-iphone678 {
                                    right: -15px;
                                }
                            }

                            .show-prev.show.read-carefully {
                                padding-right: 11px;

                                    @include for-iphone678 {
                                        padding-right: 8px;
                                    }
                            }
                        }

                        &.agreement {
                            flex-grow: 1;

                            .show-prev.show {
                                @include for-iphone678 {
                                    padding-right: 14px;
                                }

                                &:after {
                                    right: -12px;
    
                                    @include for-iphone678 {
                                        right: -14px;
                                    }
                                }
                            }
                            
                        }
                    }
                    
                    .show-prev {
                        overflow: hidden;
                        display: none;
                        position: relative;
                        max-width: 100px;
                        
                        span.num-circle {
                            background: rgba(12, 39, 108, 0.3);
                            border: 1px solid rgba(12, 39, 108, 0.3);
                            color: white;
                        }
                        
                        &.show {
                            display: flex;
                            overflow: visible;
                            
                            &:after {
                                content: '';
                                background-image: url('/arrow-icon.png');
                                background-position: center;
                                height: 8px;
                                width: 8px;
                                position: absolute;
                                bottom: 50%;
                                right: -5px;
                                transform: translate(0, 50%);
                            }
                            
                            &.start-header {
                                padding-right: 8px;
                            }
                            
                            &.fill-info, &.read-carefully, &.sign {
                                padding-left: 8px;
                                padding-right: 8px;
                            }
                            
                            &:last-of-type {
                                &:after {
                                    display: none;
                                }
                            }
                        }
                        
                    }
                }
                
                // from now onwards, the code is separated into two sections. The mobile version is the first half, and the tablet + version the second

                // mobile version from here:
                .current-nav-container{
                    display: flex;
                    align-items: center;
                    flex-grow: 1;

                    &.read-carefully {
                        justify-content: space-evenly;

                        @supports (-ms-accelerator: true) {
                            /* Edge only */
                              justify-content: space-around;
                              /* you can also add some other adjustments to size, margins etc to get the same positioning */
                        }

                        li {
                            &.read-carefully.active {
                                width: 97px;
                            }

                            &.sign.hide-number  {
                                width: 54px;
                            }
                        }
                    }

                    &.sign {
                        justify-content: center;
                        width: 32%;

                        @include for-iphone678 {
                            width: 27%;
                        }

                        @include for-iphone678plus {
                            padding-right: 26px;
                        }

                        @include for-tablet-portrait-up {
                            padding-right: 0;
                        }
                    }

                    &.agreement {
                        flex-grow: 0;
                        justify-content: flex-end;
                    }

                    li {
                        display: inline-flex;
                        width: 33%;
                        color:#B8B8B8;
                        position:relative; 
                        list-style:none; 
                        justify-content: center;
                        align-items: center;

                        // display arrow after 
                    &:after {
                        content: '';
                        background-image: url('/arrow-icon.png');
                        background-position: center;
                        background-size: cover;
                        height: 12px;
                        width: 12px;
                        position: absolute;
                        bottom: 50%;
                        right: 0px;
                        transform: translate(0, 50%);
                    }
        
                        // extra classes
                        &.hide-text, &.hide-number, &.active {
                            margin-left: 0;
                            margin-right: 0;
                            padding-left: 0;
                            padding-right: 12px;
                            text-align: center;
                        }
        
                        // remove an arrow after 5 (new agreement)
                        &.agreement:after {
                            display: none;
                        }
                        
                        // DONT SHOW NUMBER
                        &.hide-number {
        
                            // don't display number
                            span.num-circle {
                                display: none;
                            }
        
                            // text in navbar
                            span.text {
                                margin-left: auto;
                                text-align: initial;
                            }
                            // fill in information
                            &.fill-info {
                                width: 40%;
                                
                                span.text {
                                    width: 70%;

                                    @include for-iphone678plus {
                                        width: auto;
                                    }
                                }
                            }
                            // read carefully
                            &.read-carefully {
                                span.text {
                                    width: 50px;
                                }
                            }
                            // sign
                            &.sign {
                                width: calc(26% - 19px);
                            }
                            // new agreement
                            &.agreement {
                                width: 70px;
                                padding-left: 9px;
                            }
                            
                        }
        
                        &.hide-text {
                            width: 16%;
                            margin: auto;
                            justify-content: center;
        
                            span.text {
                            display: none;
                            padding-right: 0;
        
                            }
        
                            span.num-circle {
                                margin-right: 0;
                            }
                            // new agreement
                            &:last-child {
                                margin: 0;
                            }
                        }
                        
                        // 'new agreement' shouldn't have padding right since it doesn't have an arrow 
                        &.agreement {
                            padding-right: 0px;
        
                            &:after {
                                background-image: none;
                            }
                        }
        
                        // when user is on this part of the form
                        &.active {
                            align-items: center;
        
                            // place border underneath text
                            .text {
                                display: inline-flex;
                                border-bottom: 2px solid black;
                                box-shadow: 0px 2px 0px -1px rgba(0, 0, 0, 0.15);
                                color: black;
                                align-items: center;
                                width: auto;
                            }
                            
                            // make bg of circle blue and colour of text white
                            span.num-circle {
                                background-color: #09256D;
                                color: white;
                                position: absolute;
                                left: -6px;
                            }
                            
                            // all except for start
                            &.fill-info, &.read-carefully, &.sign, &.agreement {
                                padding-left: 19px;
                                margin-left: 15px;
                            }
                            // start
                            &.start-header {
                                width: 31%;
        
                                @include for-iphone678 {
                                    width: 28%;
                                }
        
                                span.num-circle {
                                    position: static;
                                    margin-right: 0;
                                }
                            }
                            // fill in information
                            &.fill-info {
                                width: 46%;
        
                                @include for-iphone678 {
                                    width: 113px;
                                }
        
                                span.text {
                                    width: 90%;
        
                                    @include for-iphone678 {
                                        width: 71px;
                                    }
        
                                }
        
                            }
                            // read carefully
                            &.read-carefully {
                                width: 35%;
                                padding-left: 14px;
        
                                @include for-iphone678 {
                                    width: 29%;
                                }

                                
                                span.text {
                                    width: 75%;

                                    @include for-iphone678plus {
                                        width: 64%;
                                    }
                                }
                            }
                            // sign
                            &.sign {
                                width: 70px;
        
                                @include for-iphone678 {
                                    width: 70px;
                                }
        
                                span.num-circle {
                                    left: -1px;
                                } 
                            }
                            // new agreement
                            &.agreement {
                                width: 87px;
                                padding-left: 22px;
        
                                @include for-iphone678 {
                                    width: 87px;
                                }
                                
                            }
                            
                        }
                        
                        // remove entire list item from view (if user is past that stage)
                        &.hide {
                        display: none;
        
                            span {
                                display: none;
                            }
        
                        }
        
                        // displays the grey arrow
                        &.grey-arrow {
                            width: 8px; 
                            padding-left: 0;
                            transition: transform .5s;
        
                            // hide text and circle with number in
                            span.text {
                                display: none;
                            }
        
                            span.num-circle {
                                display: none;
                            }
        
                            // place arrow in box further to left (so it isn't after span)
                            &:after {
                                background-color: #EFEFEF;
                            }
    
                            &.rotate {
                                transform: rotate(-180deg);
                                transition: transform .5s;
                            }
                        }
        
                        // ipad screen size and above
                        @include for-tablet-portrait-up {
                            display: inline-flex;
        
                            &.active, &.hide, &.hide-number, &.hide-text, &.grey-arrow {
                                margin-left: 0;
                                margin-right: 0;
                                padding-left: 0;
                                padding-right: 8px;
                                text-align: center;
        
                                display: inline-flex;
                                justify-content: space-around;


                                @supports (-ms-accelerator: true) {
                                    /* Edge only */
                                      justify-content: space-around;
                                      /* you can also add some other adjustments to size, margins etc to get the same positioning */
                                }
                            }
                            // for any classes added to li
                            &.hide-number, &.hide, &.hide-text, &.active {
        
                                span.text {
                                    display: inline-flex;
                                    margin: 0 auto;
                                }
            
                                span.num-circle {
                                    display: inline-flex;
                                    align-items: center;
                                    justify-content: center;
                                }
        
                                span.text {
                                    margin-left: unset;
                                    margin: unset;
                                }
                            }
                            // for active
                            &.active {
                                &:nth-child(n + 2) {
                                        padding-left: 0;
                                        margin-left: 0;
        
                                        span.num-circle {
                                            position: static;
                                        }
                                }
                            }
                            
        
                            // start
                            &.start-header {
        
                                &.active, &.hide {
                                    width: 15%;
        
                                    span.text {
                                        display: inline-flex;
                                        margin: unset;
                                    }
        
                                    span.num-circle {
                                        display: inline-flex;
                                        align-items: center;
                                        justify-content: center;
                                    }
        
                                    &:after {
                                        background-color: transparent;
                                    }
        
                                }
                            }
        
                            // fill in information
                            &.fill-info {
        
                                &.hide, &.active, &.hide-text, &.hide-number {
                                    width: 30%;
                                }
        
                                &.hide-number, &.active {
                                    span.text {
                                        width: unset;
                                    }
                                }  
                                
                                &.hide-text {
                                    max-width: auto;
                                    max-width: unset;
                                }
        
                            }
        
                            // read carefully
                            &.read-carefully {
                                &.hide, &.active, &.hide-text, &.hide-number {
                                    width: 25%;
                                    max-width: auto;
                                    max-width: unset;
        
                                    &.hide-number, &.active {
                                        span.text {
                                            width: auto;
                                            width: unset;
                                        }
                                    }
                                }
                            }
                            // sign
                            &.sign {
                                &.hide, &.active, &.hide-text, &.hide-number {
                                    width: 15%;
                                }
                            }
                            // new agreement
                            &.agreement {
                                &.hide, &.active, &.hide-text, &.hide-number {
                                    padding-right: 0;
                                    padding-left: 0;
                                    width: 25%;
        
                                    span.num-circle {
                                        position: static;
                                    }
        
                                    span.text {
                                        margin: unset;
                                    }
                                }
                            }

                            &.clickable {
                                cursor: pointer;
                            }
                        }
                    }
                }
            }
        }
    }   
}