@import "config/fonts";

button {
  cursor: pointer;
}

// removes orange border from button
button:focus {
  outline: none !important;
}

// orange button with a small animation going from right to left (like a progress bar)
button.rounded-edge-button {
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  width: 158px;
  height: 49px;
  padding: 10px;
  height: 100%;
  display: none;
  background: #ffe8db;
  border-color: #ffe8db;
  border-width: 0;
  position: relative;
  overflow: hidden;
  z-index: 0;

  &.modal {
    display: block;
  }

  &.home {
    margin-top: 40px;
    margin-left: 175px;
  }

  span {
    font-family: $header_font_style;
    vertical-align: middle;
    font-size: 24px;
  }

  @include for-tablet-portrait-up {
    display: block;
  }

  &:before {
    content: "";
    z-index: -1;
    position: absolute;
    top: 50%;
    left: 100%;
    margin: -15px 0 0 1px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: #ffba97;
    -webkit-transform-origin: 100% 50%;
    transform-origin: 100% 50%;
    -webkit-transform: scale3d(1, 2, 1);
    transform: scale3d(1, 2, 1);
    -webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
    transition: transform 0.3s, opacity 0.3s;
    -webkit-transition-timing-function: cubic-bezier(0.7, 0, 0.9, 1);
    transition-timing-function: cubic-bezier(0.7, 0, 0.9, 1);
  }

  &:hover:before {
    -webkit-transform: scale3d(9, 9, 1);
    transform: scale3d(9, 9, 1);
  }
}

// buttons that are in mobile footer
.swiper-nav-container {
  height: 0;
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  bottom: 40px;
  left: 0;
  z-index: 1000;
  width: 100%;
  padding: 0 20px;

  @include for-tablet-portrait-up {
    display: none;
  }

  &.hide {
    display: none;
  }

  .button-container {
    button {
      display: inline-flex;
      justify-content: space-between;
      width: 50%;
      text-decoration: none;
      border-radius: 50%;

      // next button (play)
      &.next-button {
        height: 50px;
        width: 50px;
        background-color: black;
        text-align: left;
        margin-left: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        border: 5px solid black;
        // play icon
        .play {
          background-image: url("/play-icon.png");
          height: 25px;
          width: 25px;
          background-repeat: no-repeat;
          background-size: 100%;
          position: absolute;
          left: 54%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
        // tick/check mark
        .check {
          background-image: url("/check.png");
          height: 21px;
          width: 21px;
          background-repeat: no-repeat;
          background-size: 100%;
          position: absolute;
          left: 54%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
        // paper-aeroplane icon
        .paper-aeroplane {
          background-image: url("/paper-aeroplane.png");
          height: 46px;
          width: 20px;
          background-repeat: no-repeat;
          background-size: 100%;
          position: absolute;
          left: 49%;
          top: 50%;
          transform: translate(-50%, -50%);
          background-position: center;
        }

        .flag {
          background-image: url(/flag.png);
          height: 36px;
          width: 32px;
          background-repeat: no-repeat;
          background-size: 100%;
          position: absolute;
          left: 58%;
          top: 50%;
          transform: translate(-50%, -50%);
          background-position: center;
        }
      }

      // go to previous section (back-button)
      &.back-button {
        height: 31px;
        width: 31px;
        min-width: 0;
        padding: 0;
        color: #fff;
        border: 1px solid rgba(0, 0, 0, 0.14);
        display: flex;
        background: white;
        position: relative;
        align-items: center;
        justify-content: center;

        span.arrow {
          height: 10px;
          width: 10px;
          border-top: 2px solid #d3d3d3;
          border-left: 2px solid #d3d3d3;
          border-radius: 0 0 0 1px;
          transform: rotate(-45deg);
          position: absolute;
          left: 11.5px;
        }

        &:before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border-radius: inherit;
          background: white;
          box-shadow: inset 0 0 0 15.5px white;
          transform: scale3d(1, 1, 1);
          transition: box-shadow 0.3s, transform 0.3s;
        }

        &:hover {
          box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);
        }
      }
    }
    // when on stage '1' the back button should not be displayed and should not be clickable
    &.hide {
      button {
        display: none;
      }
    }

    // ACTIVE next
    &.next-container {
      @keyframes changeColour {
        0% {
          background-color: black;
          border: 5px solid #ffba97;
        }
        50% {
          background-color: #ffddcb;
          border: 5px solid #ffba97;
        }
        100% {
          background-color: #ffba97;
          border: 5px solid #ffba97;
        }
      }

      &.active {
        .next-button {
          // make the animation result permenant by using forwards
          animation: changeColour 0.3s infinite ease;
        }
      }
    }

    // ACTIVE back
    &.back-container {
      &.active {
        .back-button {
          height: 34px;
          width: 34px;

          &:before {
            content: "";
            background-color: #09256d;
            box-shadow: inset 0 0 0 2px #09256d;
            transform: scale3d(1, 1, 1);
            transition: box-shadow 0.3s, transform 0.3s;
          }

          span.arrow {
            height: 11px;
            width: 11px;
            border-top: 2px solid white;
            border-left: 2px solid white;
            z-index: 10;
            left: 13px;
            transition: height 0.3s width 0.3s;
          }
        }
      }
    }
  }
}
