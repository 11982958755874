.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
    background-color: #000;
    opacity: .5;
    transition: opacity 0.7s cubic-bezier(0.22, 0.61, 0.36, 1);
}

.modal-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0;  
    display: flex;

    @include for-tablet-portrait-up { 
        top: -88px;
    }

    .modal-container {
        z-index: 100;
        background: white;
        position: relative;
        margin: auto;
        border-radius: 6px;
        width: 264px;
        height: 252px;
        transform: translate(0, 100px);
        opacity: 0;
        transition: opacity 0.7s cubic-bezier(0.22, 0.61, 0.36, 1);
        transition: transform 0.7s cubic-bezier(0.22, 0.61, 0.36, 1);

        @include for-tablet-portrait-up {
            width: 631px;
            height: 261px;
        }

        .viewport {
            height: 100%;
            width: 100%;

            .inner {
                padding: 16px;
                height: 100%;
                width: 100%;

                @include for-tablet-portrait-up {
                    padding: 34px 25px 23px 48px;
                }

                .content {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: space-between;
                    text-align: center;
                    height: 100%;

                    @include for-tablet-portrait-up {
                        text-align: left;
                        align-items: flex-start;
                    }

                    h2 {
                        margin-block-start: 0em;
                        margin-block-end: 0em;
                        font-weight: bold;
                        line-height: 28px;

                        @include for-tablet-portrait-up {
                            margin-block-end: 0em;
                        }
                    }
                    
                    p  {
                        margin-block-start: 0em;
                        margin-block-end: 0;
                        font-size: 12px;
                        color: #B8B8B8;

                        @include for-tablet-portrait-up {
                            font-size: 14px;
                            line-height: 18px;
                            margin: auto 0 0;
                        }
                    }

                    .form-item {
                        margin: auto;

                        @include for-tablet-portrait-up {
                           margin: auto 0;
                        }

                        input {
                            @include for-tablet-portrait-up {
                                margin-right: auto;
                            }
                        }
                    }

                    .rounded-edge-button {
                        width: 131px;
                        height: 49px;
                        font-size: 24px;
                        margin-top: auto;

                        @include for-tablet-portrait-up {
                            margin-left: auto;
                            margin-top: unset;
                         }
                    }
                }
            }
        }
    }

    &.opened {
        .modal-container {
            opacity: 1;
            transform: translate(0, 0px);
        }
    }
}