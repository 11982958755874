@mixin for-landscape-mobile {
  @media (min-width: 400px) and (max-width: 900px) and (min-height: 300px) and (max-height: 414px) and (orientation: landscape) {@content;}
  // @media (max-width: 896px) and (min-aspect-ratio: 13/9) { @content; }
}
@mixin for-phone-only {
  @media (max-width: 599px) and (orientation: portrait) { @content; }
}
@mixin for-huaweip90 {
  @media (min-width: 323px) and (orientation: portrait) { @content; }
}
@mixin for-iphone678 {
  @media (min-width: 375px) and (orientation: portrait) { @content; }
}
@mixin for-iphone678plus {
  @media (min-width: 414px) and (orientation: portrait) { @content; }
}
@mixin for-tablet-portrait-up {
  @media (min-width: 600px) { @content; }
}
@mixin for-ipad-portrait-up {
  @media (min-width: 768px) { @content; }
}
@mixin for-ipad-pro-portrait-up {
  @media (min-width: 1024px)  and (min-height: 1366px) and (orientation: portrait) { @content; }
}
@mixin for-ipad-pro-landscape-up {
  @media (min-width: 1366px)  and (min-height: 1024px) and (orientation: landscape) { @content; }
}
@mixin for-tablet-landscape-up {
  @media (min-width: 900px) { @content; }
}
@mixin for-desktop-up {
  @media (min-width: 1240px) { @content; }
}
@mixin for-med-desktop-up {
  @media (min-width: 1440px) { @content; }
}
@mixin for-big-desktop-up {
  @media (min-width: 1800px) { @content; }
}
