@import "config/fonts";
@import "config/mixins";
@import "components/index";

html {
  -webkit-text-size-adjust: 100%; /* Prevent font scaling in landscape while allowing user zoom */
}

// show landscape error on  mobile landscape
html {
  @include for-landscape-mobile {
    &.mobile-safari {
      background-color: #223b7c;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      body {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #223b7c;
        height: 100%;
        width: 100%;

        main {
          height: unset !important;

          .wrapper {
            display: none;
          }
        }
      }
    }
  }
  body {
    .landscape-error {
      display: none;

      @include for-landscape-mobile {
        display: block;
        position: absolute;
        bottom: 0;
        top: 0;
        left: 0;
        right: 0;
        background-color: #223b7c;
        z-index: 999999999;

        img {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%) rotate(270deg);
        }
      }
    }

    @include for-landscape-mobile {
      main {
        display: none;
      }
    }
  }
}

body {
  margin: 0;
  font-size: 1em;
}

body.start-agree-active {
  @include for-tablet-portrait-up {
    overflow: hidden;
  }
}

* {
  box-sizing: border-box;
}

// removes blue square background on tap/highlight
input,
textarea,
button,
select,
a,
div {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

// WRAPPER/VIEWPORT
html {
  body {
    main {
      position: absolute;
      top: 44px;
      right: 0;
      bottom: 0;
      left: 0;
      overflow: hidden;

      @include for-tablet-portrait-up {
        top: 56.3px;
        overflow: visible;
      }

      .wrapper {
        height: 100%;

        @include for-tablet-portrait-up {
          position: relative;
          z-index: 0;
        }

        // all  viewports
        .viewport {
          height: 100%;
          margin: 0 auto;

          // all inners
          .inner {
            height: 100%;
            width: 100%;

            .swiper-wrapper {
              height: 100% !important;
            }
          }

          // only desktop viewport
          &.desktop {
            @include for-tablet-portrait-up {
              background: white;
              display: flex;
              max-width: 760px;
              z-index: 0;
            }

            @include for-tablet-landscape-up {
              max-width: 827px;
            }

            &.contract {
              @include for-tablet-portrait-up {
                height: 5460px;
              }

              @include for-tablet-landscape-up {
                height: 5887px;
              }

              .viewport {
                &.mobile {
                  @include for-tablet-portrait-up {
                    overflow: visible;
                  }

                  .inner.landscape {
                    height: auto;

                    @include for-tablet-portrait-up {
                      height: unset;
                    }
                  }
                }
              }
            }

            &.agree-active-desktop {
              .viewport {
                &.mobile {
                  @media (min-width: 600px) and (max-height: 755px) {
                    height: 100vh !important;
                    overflow: visible !important;
                    overflow-y: scroll !important;
                    overflow-x: hidden !important;

                    .footer-agreement-container {
                      min-height: 187px;
                      padding-bottom: 32px;
                    }
                  }

                  @include for-tablet-portrait-up {
                    height: 94vh;
                    overflow: hidden;
                  }
                }

                .inner {
                  padding-top: 41px;

                  @include for-tablet-portrait-up {
                    display: block;
                    padding-top: 209px;
                    padding-bottom: 99px;
                  }

                  @include for-tablet-landscape-up {
                    padding-top: 123px;
                    padding-bottom: 5px;
                  }

                  @include for-ipad-pro-landscape-up {
                    padding-top: 225px;
                    padding-bottom: 100px;
                  }
                }
              }
            }

            &.start-active-desktop {
              .viewport {
                &.mobile {
                  overflow: visible;

                  @media (min-width: 600px) and (max-height: 717px) {
                    height: 100% !important;
                    overflow: visible !important;
                    overflow-y: scroll !important;
                    overflow-x: hidden !important;
                  }

                  @include for-tablet-portrait-up {
                    height: 94vh;
                    overflow: hidden;
                  }
                }

                .inner {
                  @include for-tablet-portrait-up {
                    padding-bottom: 175px;
                  }
                }
              }

              &.safari {
                .viewport.mobile .inner {
                  @include for-tablet-portrait-up {
                    padding-bottom: 187px;
                  }
                }
              }
            }
          }

          &.mobile {
            width: 100%;
            overflow: hidden;
            padding: 0;

            @include for-tablet-portrait-up {
              padding: 0;
            }

            .inner {
              padding: 25px 20px 80px;
              overflow: scroll;
              position: relative;

              @include for-tablet-portrait-up {
                overflow: visible;
                padding: 0 45px;
              }

              @include for-tablet-landscape-up {
                padding: 0 65px;
              }
            }
          }

          // these sectiions have their own h2's at top which adds margin already so remove padding-top
          .part-1,
          .part-2,
          .one,
          .sign {
            .mobile {
              .inner {
                padding-top: 0;
              }
            }
          }
        }
      }
    }
  }

  &.mobile-safari {
    body {
      main {
        .wrapper {
          .swiper-container-ios {
            min-height: 100vh;

            @include for-tablet-portrait-up {
              min-height: auto;
            }
          }
        }
      }
    }
  }
}

// FONT STYLES
p,
ol,
li,
span,
textarea,
select,
input {
  font-family: $body_font_style;
}

h1,
h2,
h3,
h4,
textarea:before,
select:before,
input:before,
.party:before,
label,
.circle > span,
.form-item > span,
a,
.confirm-send-container > p,
.fill-text span,
.rounded-edge-button,
.popup > span,
span.number,
p.small-print {
  font-family: $header_font_style;
}

// H1's
h1 {
  font-size: 34px;
  font-weight: normal;
  margin-block-start: 0;

  @include for-iphone678 {
    font-size: 36px;
  }

  @include for-tablet-portrait-up {
    font-size: 54px;
    margin-block-start: 0.83em;
  }
}

// H2's
h2 {
  font-size: 23px;
  font-weight: normal;

  @include for-iphone678 {
    font-size: 25px;
  }

  @include for-tablet-portrait-up {
    font-size: 36px;
    // this agreement starts on : and the parties are considering working together on : (for tablet + ). Our mutual commitment: protecting our respective Confidential Information.
    margin-top: unset;
    margin-block-start: 1.3em;
    margin-block-end: 1.3em;
  }
}

// ALL PARAGRAPHS
p {
  font-size: 14px;
  line-height: 28px;
  width: 100%;

  @include for-iphone678 {
    font-size: 16px;
  }
}
.flowGraph {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 1.5em;
}

.flowGraph > div {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 50px;
}

.flowGraph .iconWrap {
  display: flex;
  align-items: flex-end;
  max-width: 35px;
  height: 50px;
  margin-bottom: 5px;
}

.flowGraph .icon {
  width: 100%;
}

.flowGraph .arrow {
  width: 25px;
  margin-bottom: 24px;
  margin: 0px 0px;
}

.flowGraph .label {
  font-size: 11px !important;
  line-height: 15px;
  text-align: center;
  margin: 0px;
  height: 24px;
}

@media (max-width: 600px) {
  .flowGraph {
    margin-top: 0.5em;
    margin-left: -10px;
  }

  .flowGraph .iconWrap {
    max-width: 15px;
  }

  .flowGraph .label {
    font-size: 7px !important;
    line-height: 10px;
    height: 24px;
    width: 80%;
  }

  .flowGraph .iconWrap {
    height: 40px;
  }

  .flowGraph .arrow {
    width: 10px;
    margin: 0px -10px;
  }
}

@media (max-width: 350px) {
  .flowGraph {
    margin-left: -17px;
  }
}

//  section 2, 3, 5
.text-blockend-m {
  p {
    margin-block-end: 2em;

    @include for-tablet-portrait-up {
      margin-block-end: 2em;
      margin-block-start: 2em;
    }

    &:not(:first-of-type) {
      @include for-tablet-portrait-up {
        margin-block-start: 2em;
      }
    }

    &:first-of-type {
      @include for-tablet-portrait-up {
        margin-block-start: 2.3em;
      }
    }
  }
}

// BLUE UNDERLINE
.blue-underline {
  color: inherit;
  background: rgba(255, 255, 255, 0);
  text-decoration: none;

  &.active {
    box-shadow: 0px 5px 0px 0px #e2eaff;
    transition: box-shadow 2s;
  }
}

.cross-list,
.tick-list {
  li {
    font-size: 14px;
    line-height: 20px;

    @include for-iphone678 {
      font-size: 16px;
    }

    @include for-tablet-portrait-up {
      font-size: 14px;
    }
  }
}

// for copyright
.copyright-wrapper {
  text-align: center;
  margin-top: 31px;

  @include for-tablet-portrait-up {
    position: absolute;
    bottom: 20px;
    left: 45px;
    text-align: center;
  }

  @include for-tablet-landscape-up {
    left: 65px;
  }

  p {
    font-weight: 600;
    color: #686868;
    font-family: "Abhaya Libre", serif;
    font-size: 10px;
    line-height: unset;
    margin: 0;
  }

  img {
    object-fit: contain;
    width: 70px;
    margin-top: 5px;
  }
}

// BULLETS
main {
  ul {
    line-height: 28px;
    list-style: none;
    margin: 0 0 auto auto;

    li {
      position: relative;
      margin: 0 5px 13px -15px;

      @include for-tablet-portrait-up {
        margin: 0 5px 20px -15px;
      }
    }
    // for ticks
    &.tick-list {
      position: relative;

      @include for-tablet-portrait-up {
        width: 43%;
      }

      li {
        // stars for tick list
        &:before {
          content: "";
          background: url("/star-list.png");
          background-repeat: no-repeat;
          position: absolute;
          height: 20px;
          width: 20px;
          border-bottom: none;
          left: -30px;
          background-size: 100%;

          @include for-tablet-portrait-up {
            left: -24px;
            top: -1px;
          }
        }
      }
    }
    // list with no entry as bullets
    &.cross-list {
      position: relative;

      li {
        @include for-iphone678 {
          margin-bottom: 25px;
        }

        @include for-tablet-portrait-up {
          margin-bottom: 20px;
        }

        &:before {
          content: "";
          background: url("/cross-list.png");
          background-repeat: no-repeat;
          position: absolute;
          height: 100%;
          width: 100%;
          border-bottom: none;
          left: -24px;
          top: 4px;
          background-size: 12px;
        }
      }
    }
  }
}

// split lists into two when tablet or above
.list-container {
  @include for-tablet-portrait-up {
    display: flex;
    justify-content: space-between;
  }
}

.start-agree-viewport {
  ul {
    @include for-tablet-portrait-up {
      margin-left: 24px;
    }

    &.tick-list {
      li:before {
        @include for-tablet-portrait-up {
          background-size: 15px;
          left: -28px;
          top: 5px;
        }
      }
    }
  }
}

// tick list for sections six and eight
.six,
.eight {
  ul.tick-list {
    @include for-tablet-portrait-up {
      width: 46%;
      margin: 0 0 auto 26px;
    }
  }
}

.six {
  ul.tick-list {
    li:before {
      @include for-tablet-portrait-up {
        left: -26px;
      }
    }
  }
}

// TITLES WITH ORANGE DOTS NEXT TO NUMBERS
.title-read-container {
  display: flex;
  margin-bottom: 3vw;
  width: 100%;

  // remove margin bottom for ipad and above to add margin to top and bottom of content instead
  @include for-tablet-portrait-up {
    margin-bottom: 0;
    height: auto;
    padding-top: unset;
  }

  .number {
    font-size: 36px;
    padding: auto;
    display: inline-flex;
    align-self: flex-end;
    position: relative;

    @include for-iphone678 {
      font-size: 38px;
    }

    @include for-tablet-portrait-up {
      font-size: 40px;
      margin-left: 30px;
    }

    &:after {
      content: "";
      height: 7px;
      width: 7px;
      background-color: #ffba97;
      position: absolute;
      left: 30px;
    }
  }

  h3 {
    font-size: 16px;
    margin-left: 30px;
    font-weight: lighter;
    width: 100%;
    display: inline-block;
    align-self: flex-end;
    margin-block-start: 0;
    margin-block-end: 5.4px;
    z-index: 1;

    @include for-iphone678 {
      font-size: 20px;
    }

    @include for-tablet-portrait-up {
      font-size: 22px;
    }
  }
}

.six,
.seven {
  .title-read-container {
    @include for-tablet-portrait-up {
      margin-bottom: 19.6px;
    }
  }
}

// quote marks behind start and agreement headers on desktop
.quote-header {
  max-width: 280px;
  position: relative;

  @include for-tablet-portrait-up {
    max-width: 100%;
  }

  &.desktop {
    max-width: 100%;
  }

  img.mobile {
    position: absolute;
    height: 148px;
    width: 154px;
    z-index: -1;
    right: -17px;
    top: -11px;

    @include for-tablet-portrait-up {
      display: none;
    }
  }

  img.desktop {
    display: none;

    @include for-tablet-portrait-up {
      display: block;
      position: absolute;
      height: 170px;
      width: 183px;
      z-index: -1;
      top: -55px;
    }
  }
}

// READ CAREFULLY CONTENT desktop - add margin top and bottom
@include for-tablet-portrait-up {
  .one,
  .two,
  .three,
  .four,
  .five,
  .six,
  .seven,
  .eight,
  .nine {
    .content {
      margin-top: 2.3em;
      margin-bottom: 2.3em;
    }
  }

  .three {
    .content {
      margin-top: 3.2em;
    }
  }
  .six,
  .seven {
    .content {
      margin-bottom: 1em;
    }
  }
}

// SWIPER
.swiper-container {
  height: 100%;

  @include for-tablet-portrait-up {
    height: auto;
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.25);
    -webkit-box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.25);
  }

  .swiper-wrapper {
    @include for-tablet-portrait-up {
      height: 100% !important;
    }

    .swiper-slide {
      height: 100% !important;

      @include for-tablet-portrait-up {
        overflow: visible;

        &.hide {
          display: none;
          width: 0 !important;
        }

        &.show {
          width: 100% !important;
          flex-shrink: unset;
        }
      }

      &.swiper-slide-active {
        z-index: 10;
        overflow: hidden !important;

        @include for-tablet-portrait-up {
          overflow: visible !important;
        }
      }

      section {
        height: 100% !important;
      }
    }
  }
}

// for desktop version
.contract {
  .swiper-container .swiper-wrapper {
    @include for-tablet-portrait-up {
      flex-direction: column;
    }
  }
}

.start-active-desktop,
.agree-active-desktop {
  .swiper-container .swiper-wrapper {
    @include for-tablet-portrait-up {
      .swiper-slide {
        section {
          &:not(.active) {
            display: none;
            flex-shrink: unset;
          }
        }
      }
    }
  }
}

// SECTIONS - on mobile these are the individual slides

// start section
.start {
  @include for-tablet-portrait-up {
    // hide if inactive
    display: none;
  }

  &.active {
    @include for-tablet-portrait-up {
      // height is 813px minus the height of the header
      height: 813px - 56.3px;
      display: flex;
      align-items: center;
    }
  }

  .inner {
    padding-top: 1.5em !important;

    @include for-tablet-portrait-up {
      padding-top: 3em !important;
      display: block;
    }

    .content {
      margin-left: 20px;

      @include for-tablet-portrait-up {
        margin-left: unset;
      }

      .hello {
        display: inline-block;
        font-family: $header_font_style;
        font-size: 24px;
        font-weight: bold;
        margin: 30px 0 -10px 0;

        @include for-iphone678 {
          font-size: 26px;
        }

        @include for-tablet-portrait-up {
          font-size: 36px;
          margin: 15px 0 5px 0;
        }
      }

      .subtitle {
        width: 100%;

        @include for-tablet-portrait-up {
          width: 100%;
          line-height: 32px;
        }
      }

      p {
        @include for-tablet-portrait-up {
          font-size: 18px;
        }
      }

      .agreement-container {
        display: flex;
        margin-top: 2.2vw;

        span.clock-circle {
          background-image: url("/clock-icon-amurabi.png");
          background-repeat: no-repeat;
          background-size: 100%;
          height: 41px;
          width: 40px;

          @include for-tablet-portrait-up {
            height: 46px;
            width: 46px;
          }
        }

        .fill-text {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          padding-left: 17px;
          width: 195px;

          @include for-tablet-landscape-up {
            margin-right: 10px;
            width: 237px;
          }

          span {
            font-size: 18px;

            @include for-tablet-landscape-up {
              min-width: 184px;
            }
          }

          .small-print {
            font-size: 14px;
            line-height: 18px;
            margin-top: 28px;
            width: 191px;

            @include for-iphone678 {
              font-size: 15px;
            }

            @include for-tablet-landscape-up {
              line-height: 19px;
              margin-top: 2vw;
              width: 201px;
              font-size: 16px;
            }
          }
        }

        a {
          margin-left: 25px;

          @include for-tablet-portrait-up {
            margin-left: auto;
          }
        }
      }
    }
  }
}

// SECTION 2.1 fill in information (this confidentiality agreement (this "Agreement") is made between :
.part-1 {
  .inner {
    @include for-tablet-portrait-up {
      padding-top: 33px !important;
    }

    .parties-info-wrapper {
      @include for-tablet-portrait-up {
        justify-content: flex-end;
        height: 535px;
        overflow-x: hidden;
      }

      .parties-info {
        @include for-tablet-portrait-up {
          position: relative;
        }

        .circle.large {
          z-index: -1;
          left: -51px;
          bottom: 418px;

          @include for-tablet-portrait-up {
            display: none;
          }
        }

        .party {
          max-height: 488px;

          @include for-tablet-portrait-up {
            // height: 280px;
            margin-bottom: 59px;
          }

          .form-item {
            margin-bottom: 11px;

            @include for-tablet-portrait-up {
              margin-bottom: 7px;
            }

            &.comp-type {
              margin-bottom: 11px;

              @include for-tablet-portrait-up {
                margin-bottom: 7px;
              }

              &.active {
                margin-bottom: 0px;

                @include for-tablet-portrait-up {
                  margin-bottom: 7px;
                  position: relative;
                }
              }
            }

            &.address {
              @include for-tablet-portrait-up {
                margin-bottom: 11px;
              }

              &.active {
                margin-bottom: 0px;
                @include for-tablet-portrait-up {
                  margin-bottom: 11px;
                }
              }
            }
          }
        }
        @include for-tablet-portrait-up {
          .icon-wrapper {
            margin-bottom: -6px;

            textarea {
              height: 75px;
            }
          }
        }
      }
    }

    .quote-header,
    p {
      @include for-tablet-portrait-up {
        padding-left: 45px;
        padding-right: 45px;
      }

      @include for-tablet-portrait-up {
        padding-left: 65px;
        padding-right: 65px;
      }
    }
  }
}

// SECTION 2.2 - fill in information (the parties are considering working together on:)
.part-2 {
  display: flex;
  flex-direction: column;
  z-index: 1;

  .inner {
    &.active {
      position: relative;

      @include for-tablet-portrait-up {
        z-index: unset;
        overflow-x: hidden;
      }
    }
    & > h2 {
      position: relative;

      @include for-tablet-portrait-up {
        width: 536px;
        margin-bottom: 35px;
      }
    }

    .agreement-start-header {
      @include for-tablet-portrait-up {
        margin-top: 35px;
        margin-bottom: 58px;
      }
    }
  }

  .start-project-container {
    position: relative;

    @include for-tablet-portrait-up {
      width: 266px;
      width: auto;
      z-index: 1;
    }

    .the-project {
      position: relative;
      margin: 0 auto;

      @include for-tablet-portrait-up {
        margin-right: auto;
        margin-left: unset;
        width: 590px;
      }

      .icon-wrapper {
        margin-right: auto;
        padding-left: 90px;

        @include for-tablet-portrait-up {
          margin: 0;
          margin-right: auto;
          margin-left: 26px;
          padding-left: 117px;
        }

        &:before {
          content: '"The Project"';
          font-size: 14px;
          position: absolute;
          left: 0px;
          top: 10px;
          width: 90px;
          padding-bottom: 7px;
          border-bottom: solid 0.5px #575757;
          color: #575757;
          z-index: 1;

          @include for-tablet-portrait-up {
            top: 15px;
            font-size: 20px;
            width: 117px;
          }
        }

        textarea {
          width: 180px;
          flex-grow: 1;
          height: 103px;
          line-height: 18px;
          padding-top: 1px;

          @include for-iphone678 {
            width: calc(180px + 16vw);
          }

          @include for-iphone678plus {
            width: calc(180px + 20vw);
          }

          @include for-tablet-portrait-up {
            flex-grow: 0;
            width: 340px;
            height: 90px;
          }

          &::-webkit-input-placeholder {
            /* Edge */
            line-height: 20px;
          }

          &:-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            line-height: 20px;
          }

          &::placeholder {
            line-height: 20px;
          }
        }
      }
    }
    // this agreement starts on :
    h2 {
      margin-top: 8px;
      // this agreement starts on : and the parties are considering working together on : (for tablet + )
      @include for-tablet-landscape-up {
        margin-top: unset;
        margin-block-start: 1.66em;
        margin-block-end: 1.66em;
      }
    }

    .date-input {
      display: flex;
      position: relative;
      justify-content: flex-start;
      flex-direction: row;
      z-index: 2;

      @include for-tablet-portrait-up {
        margin-left: 143px;
        justify-content: unset;

        &.form-item {
          justify-content: unset;

          &.ie11 {
            justify-content: flex-start;
          }
        }
      }

      .starting-date-label {
        display: none;

        @include for-tablet-portrait-up {
          display: block;
          position: absolute;
          z-index: 2;
          font-size: 8px;
          left: 8px;
          top: 3px;
          color: #ffba97;
        }
      }

      input {
        position: relative;

        &:before {
          content: "Starting date:";
          position: absolute;
          z-index: 2;
          font-size: 8px;
          left: 8px;
          top: 3px;
          color: #ffba97;

          @include for-tablet-portrait-up {
            display: none;
          }
        }
      }

      // xxx For xxx
      label {
        font-size: 14px;
        margin: auto 25px auto 25px;

        @include for-iphone678 {
          font-size: 16px;
        }

        @include for-tablet-portrait-up {
          font-size: 16px;
        }
      }

      // give a height to date input and select
      input[type="date" i],
      select {
        height: 36px;
      }

      input[type="date"] {
        width: 114px;

        @include for-iphone678 {
          width: calc(140px + 10vw);
        }

        @include for-tablet-portrait-up {
          width: 140px;
        }
      }

      .mobile {
        @include for-tablet-portrait-up {
          display: none;
        }
      }

      .desktop {
        display: none;

        @include for-tablet-portrait-up {
          display: block;
        }
      }

      // position dropdown icon
      select {
        background-size: 10%;
        background-position-x: 87%;
        background-position-y: 50%;

        @include for-iphone678plus {
          width: calc(80px + 7vw);
        }

        @include for-tablet-portrait-up {
          height: 40px;
          width: 80px;
        }
      }
    }

    .form-date-wrapper {
      position: relative;

      @include for-tablet-portrait-up {
        left: -46px;
      }
    }

    .end-date-project-container {
      display: flex;
      position: absolute;
      bottom: -49px;
      left: 35px;
      width: 190px;

      @include for-iphone678 {
        position: absolute;
        left: 51px;
      }

      @include for-tablet-portrait-up {
        position: absolute;
        bottom: -51px;
        left: 57px;
      }
      .circle {
        margin-right: auto;

        @include for-tablet-portrait-up {
          margin-right: auto;
        }
      }

      .end-text {
        position: absolute;
        color: white;
        top: 17px;
        left: 21px;
      }

      h2 {
        font-size: 24px;
        position: absolute;
        right: -20px;
        display: block;
        width: auto;
        top: 21px;
      }
    }

    .date-text {
      margin: 0;
      align-self: center;
    }
  }

  .termination-date {
    margin-top: 45px;

    @include for-tablet-portrait-up {
      margin-top: 60px;
      display: flex;
      z-index: 1;
    }

    input.termination-date-input {
      height: 36px;
      width: 70px;
      min-height: 36px;
      min-width: 70px;

      @include for-tablet-portrait-up {
        padding-bottom: 3px;
        height: 40px;
      }
    }

    p {
      @include for-tablet-portrait-up {
        width: 333px;
      }
    }

    .form-item {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;

      @include for-tablet-portrait-up {
        flex-wrap: nowrap;
      }
    }

    .icon-wrapper {
      align-items: center;
      flex-direction: row;
      justify-content: unset;
      width: auto;

      @include for-tablet-portrait-up {
        width: auto;
        z-index: 1;
        justify-content: center;
      }

      input {
        margin-right: 22px;
      }
    }

    label {
      white-space: nowrap;
      align-self: flex-end;

      @include for-tablet-portrait-up {
        white-space: normal;
        align-self: auto;
      }
    }
  }

  select {
    width: 80px;
  }
}

// SECTION 3.1 - confidential-information
.one {
  position: relative;

  .viewport {
    &.mobile {
      @include for-tablet-portrait-up {
        overflow: visible !important;
      }
    }

    .title-read-container {
      padding-top: 0;
      margin-top: 24px;
    }

    .content {
      p:first-child {
        @include for-tablet-portrait-up {
          margin-bottom: 30px;
        }
      }

      p:nth-child(2) {
        &:not(.helper-text) {
          @include for-tablet-portrait-up {
            margin-bottom: -5px;
          }
        }
      }
    }

    .information-container {
      display: flex;
      flex-direction: column;

      @include for-tablet-portrait-up {
        flex-direction: row;
      }

      .form-item {
        &.info-text-area {
          display: flex;
          flex-direction: column;

          &.desktop {
            display: none;

            @include for-tablet-portrait-up {
              margin-right: 25px;
              width: 308px;
              display: flex;
            }
          }

          &.mobile {
            @include for-tablet-portrait-up {
              display: none;
            }
          }

          textarea {
            margin: auto;
            width: 245px;
            height: 68px;
            padding-top: 4px;

            @include for-tablet-portrait-up {
              width: 300px;
              height: 100px;
              padding-top: 4px;
            }

            &::-webkit-input-placeholder {
              /* Edge */
              line-height: 25px;
            }

            &:-ms-input-placeholder {
              /* Internet Explorer 10-11 */
              line-height: 25px;
            }

            &::placeholder {
              line-height: 25px;
            }

            &:focus {
              &::-webkit-input-placeholder {
                /* Edge */
                color: transparent;
              }

              &:-ms-input-placeholder {
                /* Internet Explorer 10-11 */
                color: transparent;
              }

              &::placeholder {
                color: transparent;
              }
            }
          }
        }
      }

      .orange-bg {
        margin: 13px auto;
        background-color: rgba(255, 232, 220, 0.7);
        border-radius: 7px;
        width: 281px;
        height: 103;
        display: flex;
        justify-content: center;
        padding: 10px 13px;

        @include for-iphone678 {
          width: 300px;
        }

        @include for-tablet-portrait-up {
          background-color: transparent;
          margin-left: 0;
          width: 305px;
        }

        p {
          font-size: 12px;
          line-height: 20px;
          margin-block-start: 0;
          margin-block-end: 0;
          margin-inline-start: 0px;
          margin-inline-end: 0px;

          @include for-iphone678 {
            font-size: 14px;
          }

          @include for-tablet-portrait-up {
            margin-block-end: 0;
            line-height: 25px;
          }
        }
      }
    }
  }
}

// SECTION 3.3 - do's and dont's
.three {
  .do-dont-container {
    display: flex;
    flex-direction: column;
    width: 100%;

    @include for-tablet-portrait-up {
      flex-direction: row;
      justify-content: space-between;
    }

    .do,
    .dont {
      margin-top: 3vw;

      @include for-tablet-portrait-up {
        width: 45%;
        margin-top: unset;
      }
    }

    @include for-tablet-portrait-up {
      .text-blockend-m {
        p:first-of-type {
          margin-block-start: 1em;
        }

        p:last-of-type {
          margin-block-end: 0;
        }
      }
    }
  }
}

// SECTION 3.4 - how will the parties communicate confidential information
.four {
  @include for-tablet-portrait-up {
    margin-bottom: 40px;
  }

  .inner {
    @include for-tablet-portrait-up {
      overflow-x: hidden !important;
    }
  }

  .title-read-container,
  p.sub-title {
    @include for-tablet-portrait-up {
      padding-left: 65px;
    }
  }

  p.sub-title {
    @include for-tablet-portrait-up {
      width: 641px;
    }
  }

  .parties-info-wrapper {
    @include for-tablet-portrait-up {
      justify-content: center;
      height: 169px;
    }

    .parties-info {
      @include for-tablet-portrait-up {
        padding-left: 45px;
      }

      @include for-tablet-portrait-up {
        padding-left: 65px;
      }

      .party {
        &.part-a {
          @include for-tablet-portrait-up {
            margin: auto;
          }
        }

        &.part-b {
          @include for-tablet-portrait-up {
            margin: auto;
          }
        }
      }

      .circle.large {
        left: -37px;
        bottom: 126px;
        height: 103px;
        width: 103px;

        @include for-tablet-portrait-up {
          height: 137px;
          width: 137px;
          left: 216px;
          bottom: -48px;
          left: 54%;
          transform: translate(-50%, 0);

          span {
            font-size: 76px;
          }
        }
      }
    }
  }
}

// SECTION 3.7 - how will a dispute between the parties be solved
.seven {
  p:first-child {
    @include for-tablet-portrait-up {
      margin-bottom: 0;
    }
  }

  input {
    height: 36px;
    width: 70px;

    @include for-tablet-portrait-up {
      width: 88px;
      height: 40px;
    }
  }

  @include for-tablet-portrait-up {
    label {
      line-height: 22px;
    }
  }

  @include for-tablet-portrait-up {
    & > .form-item {
      margin-bottom: 60px;
    }
  }

  .form-item {
    &.legal {
      flex-direction: row;
      justify-content: flex-start;
      align-items: space-between;

      @include for-tablet-portrait-up {
        justify-content: unset;
        flex-direction: row;
        align-items: center;
        // margin-top and margin-bottom in horizontal-align.legal are same to give same distance between p in between them
        margin: auto 0;
        margin-top: 20px;
      }

      &:first-of-type {
        @include for-tablet-portrait-up {
          margin-bottom: 60px;
        }
      }
    }
  }
  .form-items-container-legal {
    @include for-tablet-portrait-up {
      flex-direction: row;
      display: flex;
      margin-top: 20px;
      margin-bottom: 60px;
    }

    .form-item {
      &:first-child {
        margin-bottom: 24px;

        @include for-tablet-portrait-up {
          margin: 0;
        }
      }

      &:last-child {
        @include for-tablet-portrait-up {
          margin: 0 auto;
        }
      }
    }
  }
}

.eight {
  .content {
    p {
      @include for-tablet-portrait-up {
        max-width: 566px;
      }
    }
  }
}

// SECTION 3.9 - anything else we need to know about this agreement?
.nine {
  .content {
    display: flex;
    flex-direction: column;

    @include for-tablet-portrait-up {
      display: block;
    }
  }
  .infos {
    background: #fcfcfc;
    border: 1px solid rgba(255, 232, 220, 0.8);
    box-sizing: border-box;
    border-radius: 9px 2px 3px 3px;
    margin-top: 10px;
    max-height: 350px;
    position: relative;

    @include for-tablet-portrait-up {
      max-width: 614px;

      &:not(:last-child) {
        margin: 18px auto;
      }

      &:last-child {
        margin: 0 auto;
      }
    }

    .title-bg {
      border-radius: 9px 2px 0px 0px;
      color: #ffffff;
      cursor: pointer;

      &.orange {
        background-color: #ffba97;
      }

      &.blue {
        background-color: #09256d;
      }

      h4 {
        padding: 10px 20px 10px 13px;
        margin: 0;
        font-size: 16px;
        font-weight: 400;

        @include for-iphone678 {
          font-size: 18px;
        }

        @include for-tablet-portrait-up {
          padding: 10px 13px;
          -webkit-user-select: none;
          -moz-user-select: none; /* Firefox */
          -ms-user-select: none; /* IE10+ */
        }
        // triangle
        span {
          width: 0;
          height: 0;
          border-left: 4px solid transparent;
          border-right: 4px solid transparent;
          border-bottom: 7px solid white;
          transform: rotate(180deg);
          transition: transform 0.4s ease;
          position: absolute;
          right: 10px;
          top: 15px;
        }
      }
    }

    .text {
      padding: 0 13px;
      max-height: 0;
      overflow: hidden;
      // transition: max-height 0.5s;
      transition: max-height 0.4s cubic-bezier(0, 1, 0, 1);

      p {
        line-height: 20px;
      }
    }

    // when active class is added
    &.active {
      h4 {
        // rotate triangle
        span {
          transform: rotate(0deg);
          transition: transform 0.4s ease;
        }
      }
      // show hidden text
      .text {
        max-height: 300px;
        // transition: max-height 0.5s;
        transition: max-height 0.4s ease-in-out;
      }
    }
  }
}

// SECTION 4 - SIGNATURE
.sign {
  .viewport {
    &.mobile {
      .inner {
        h2,
        .signature-subtitle {
          @include for-tablet-portrait-up {
            padding-left: 45px;
          }

          @include for-tablet-portrait-up {
            padding-left: 65px;
          }
        }

        .signature-subtitle {
          @include for-tablet-portrait-up {
            width: 578px;
          }
        }
      }
    }
  }
  .parties-info-wrapper {
    @include for-tablet-portrait-up {
      margin-top: 25px;
    }

    .parties-info {
      @include for-tablet-portrait-up {
        align-items: center;
        height: 330px;
      }

      .circle.large {
        left: -48px;
        bottom: 268px;

        // adjust circle position for ipad and up
        @include for-tablet-portrait-up {
          height: 142px;
          width: 142px;
          left: 50%;
          transform: translate(-50%, 0);
          bottom: -23px;

          span {
            font-size: 74px;
          }
        }
      }

      .party {
        @include for-tablet-portrait-up {
          position: relative;
          height: 271px;
        }

        @include for-tablet-portrait-up {
          &.part-a {
            margin: auto 0 auto auto !important;
          }

          &.part-b {
            margin: 0 auto !important;
          }
        }

        // add margin-bottom to all form-items except for mobile phone
        .form-item {
          &:not(:nth-child(4)) {
            margin-bottom: 7px;
          }

          &.main-contact.signature {
            &.active {
              margin-bottom: 0px;

              @include for-tablet-portrait-up {
                margin-bottom: 7px;
              }
            }
          }

          &:nth-child(4) {
            margin-bottom: 0px;
          }
        }

        // dustify-content: space-between authorized and yousign button
        .signature-container {
          display: flex;
          width: 180px;

          @include for-tablet-portrait-up {
            justify-content: space-between;
            width: 204px;
            height: auto;
            margin-top: 15px;
            flex-direction: row;
          }

          .form-item {
            margin-left: auto;
            margin-bottom: auto;
            margin-top: 15px;

            @include for-tablet-portrait-up {
              margin-top: unset;
              margin: unset;
              margin-left: unset;
              margin-bottom: auto;
            }

            // duly authorized
            span {
              margin-left: auto;
              font-size: 14px;
              height: 22px;
              border-bottom: 1px solid rgba(0, 0, 0, 0.5);

              @include for-iphone678 {
                font-size: 15px;
              }

              @include for-tablet-portrait-up {
                font-size: 16px;
              }
            }
          }
        }
      }
    }
  }

  .confirm-send-container {
    display: none;
    height: 0;
    width: 0;

    @include for-tablet-portrait-up {
      height: 288px;
      width: auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      p {
        font-size: 22px;
        width: 456px;
      }

      // confirm and send button
      button {
        width: 222px;
        height: 49px;
      }
    }
  }
}

// last section
.agreement-section {
  @include for-tablet-portrait-up {
    display: none;
  }

  &.active {
    @include for-tablet-portrait-up {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
    }
  }

  .inner {
    display: flex;
    flex-direction: column;

    @include for-tablet-portrait-up {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }

  .quote-header {
    img.desktop {
      @include for-desktop-up {
        height: 179px;
        width: 199px;
      }
    }
  }

  .wrapper-container {
    @include for-tablet-portrait-up {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  .quote-header {
    h2 {
      margin-block-start: unset;

      @include for-tablet-landscape-up {
        margin-block-end: 0.83em;
      }
    }

    img {
      @include for-tablet-portrait-up {
        top: -108px;
      }
    }
  }

  .content-container {
    display: flex;
    flex-direction: column;

    @include for-tablet-portrait-up {
      flex-grow: 1;
      padding-right: 85px;
      padding-left: 45px;
    }

    @include for-tablet-landscape-up {
      padding-left: 65px;
    }

    @include for-ipad-pro-portrait-up {
      justify-content: center;
      flex-grow: 0;
    }

    p {
      @include for-tablet-portrait-up {
        margin-bottom: 23px;
      }
    }

    li {
      @include for-tablet-portrait-up {
        margin: 0 5px 24px -9px;
      }
    }
  }

  .footer-agreement-container {
    @include for-tablet-portrait-up {
      position: relative;
      z-index: 10;
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    @include for-ipad-pro-portrait-up {
      margin-top: 72px;
      flex-grow: 0.2;
    }

    @include for-desktop-up {
      margin-top: 84px;
    }

    .home-page {
      height: 0;
      width: 0;
      display: none;

      @include for-tablet-portrait-up {
        display: inline-block;
        width: auto;
        visibility: visible;
        text-decoration: underline;
        color: black;
        font-size: 20px;
        line-height: 24px;
        padding-left: 45px;

        &:hover {
          color: #09256d;
        }
      }

      @include for-tablet-landscape-up {
        padding-left: 65px;
      }
    }

    // POPUP
    .popup {
      background: #09256d;
      box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
      border-radius: 3px;
      color: white;
      padding: 15px;
      font-weight: normal;
      opacity: 1;

      @include for-tablet-portrait-up {
        width: 727px;
        margin: 30px auto 30px;
        height: 60px;
        display: flex;
        align-items: center;

        span {
          padding-left: 12px;
        }
      }

      @include for-tablet-landscape-up {
        width: 742px;
      }

      @include for-desktop-up {
        margin-top: 100px;
      }

      &.active {
        opacity: 0;
        transition: opacity 10s linear;
      }
    }
  }
}

.react-phone-input {
  width: 100%;
  left: 0px;
}

.react-phone-input .PhoneInput {
  display: flex;
  width: 100%;
  gap: 8px;
}

.react-phone-input .PhoneInputCountry {
  padding-left: 0px;
  padding-top: 12px;
  width: 30px;
}

.react-phone-input .PhoneInputCountry .PhoneInputCountrySelect {
  display: none;
}
