@import 'config/mixins';
@import 'config/fonts';

// FORM ITEMS
textarea, input, select {
    width: inherit;
    box-sizing: border-box;
    background: #F1F5FF;
    border-radius: 6px 6px 0px 0px;
    border: none !important;
    border-bottom: solid 1px black !important;
    font-weight: lighter;
    padding: 10px 7px 0 7px;
    font: 400 10px $body_font_style; 

    @include for-iphone678 {
        font-size: 11px;
    }

    @include for-tablet-portrait-up  {
        font-size: 11px;
    }

    &:before {
        font-family: $header_font_style;
    }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

// remove spinner on number on firefox
input[type=number] {
    -moz-appearance:textfield;
}

// make bottom border and text blue on focus
textarea, select, input {
    color: rgba(0, 0, 0, 0.5);
    
    &:focus {
        color: #09256D;
        border-bottom: solid 1px #09256D !important;
        outline: none;
    }
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url("/triangle-dropdown.png");
    background-repeat: no-repeat;
    background-position-y: 50%;
}

label {
    font-weight: bold;
    font-size: 14px;

    @include for-iphone678 {
        font-size: 16px;
    }

    @include for-tablet-portrait-up {
        font-size: 16px;
    }
}

// make bottom border and text red and show an icon on error 
.form-item.error, .icon-wrapper.error, .error-input-wrap.error {
    input, textarea, select {
        color: #AE0726;
        border-bottom: solid 2px #AE0726 !important;
    
        &::placeholder {
            color: #AE0726;
        }
    }
}

.form-item.error, .error-input-wrap.error {
    position: relative;

    &:after {
        content: '';
        background-image: url('/info-icon-error.png');
        background-size: cover;
        position: absolute;
        width: 15px;
        height: 15px;
        right: 6px;
        top: 13px;
    }

    &.textarea {
        &:after {
            top: 5px;
        }
    }
}

// doesn't allow resizing of textarea (by dragging bottom right corner of textarea)
textarea {
    resize: none;
}

// DATE
input[type="date" i] {
    font: 400 9.5px $body_font_style; 
}
.date-input {
    .react-datepicker__input-container {
        display: none;
    
        @include for-tablet-portrait-up {
            display: inline-block;
        }

        input[type="text"] {
            height: 36px;
            width: 114px;
            background-image: url('/calendar.png');
            background-size: 11%;
            background-position: 90% 65%;
            background-repeat: no-repeat;
            font-size: 10px;
    
            @include for-iphone678 {
                font-size: 11px;
            }
        
            @include for-tablet-portrait-up  {
                font-size: 10px;
                height: 40px;
            }
        }
    }

    &.form-item.resized-mobile  .react-datepicker__input-container {
        display: inline-block;             
    }
}

// info icon in form input and displaying message
.form-item {
    z-index: 0;

    .icon-wrapper {
        position: relative;
        z-index: 0;
        width: 100%;

        img {
            position: absolute;
            right: 6px;
            width: 15px;
            height: 15px;
            bottom: 7px;

            &:hover {
                cursor: pointer;
            }

            &.textarea-icon {
                bottom: 0px;
                top: 12px;
            }
        }

        input, textarea {
            z-index: 0;
            padding: 7px 21px 0 7px;

            @include for-tablet-portrait-up {
                padding-top: 10px;
            }
        }

    }

    .helper-text-container {
        overflow: hidden;
        max-height: 0px;
        transition: max-height 0.5s;
        background-color: #09256D; 
        padding: 0 5px;
        padding-right: 16px;
        position: relative;

        @include for-tablet-portrait-up {
            position: absolute;
            // background: rgba(255,255,255,0);
            border-radius: 6px;
            z-index: 5;
            padding: 5px 10px;
            padding-right: 12px;
            background: #FFFFFF;
            box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
            opacity: 0;
            transition: opacity 0s, max-height 0s;
            margin-top: 0;

            &:after {
                content: '';
                background-image: url(/triangle-dropdown-blue.png);
                background-repeat: no-repeat;
                background-size: cover;
                width: 8px;
                height: 7px;
                position: absolute;
                right: 8px;
                bottom: -6px;
            }
        }

        .close {
            position: absolute;
            height: 10px;
            width: 10px;
            right: 2px;
            z-index: 1;
            top: 5px;
            cursor: pointer;

            @include for-tablet-portrait-up {
                top: 6px;
            }


            &:before, &:after {
                // @include for-tablet-portrait-up {
                    content: '';
                    cursor: pointer;
                    z-index: 10;
                    display: block;
                    height: 2px;
                    width: 10px;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    margin-left: -8px;
                    margin-top: -1px;
                    background-color: white;
                // }
            }

            &:before {
                // @include for-tablet-portrait-up {
                    -webkit-transform: rotate(-45deg);
                    -moz-transform: rotate(-45deg);
                    -ms-transform: rotate(-45deg);
                    transform: rotate(-45deg);
                // }
            }

            &:after {
                // @include for-tablet-portrait-up {
                    -webkit-transform: rotate(45deg);
                    -moz-transform: rotate(45deg);
                    -ms-transform: rotate(45deg);
                    transform: rotate(45deg);
                // }
            }
        }

        .helper-text {
            font-size: 11px;
            line-height: 12px;
            letter-spacing: -0.7px;
            color: #B8B8B8;
            font-family: "GTAmerica";
            color: white;
            margin: 6px 0;

            @include for-tablet-portrait-up {
                padding: 0;
                margin: 0;
                letter-spacing: -0.03em;
            }
        }
    }

    &.active {
        .helper-text-container {
            transition: max-height 0.5s;
            margin-bottom: 3px;
            background-color: #09256D;
            
            @include for-tablet-portrait-up {
                max-height: 70px;
                opacity: 1;
                transition: max-height, opacity 0.5s;
                overflow: visible;
                margin-bottom: unset;
            }
        }
    }

    &.comp-type {
        img {
            @include for-tablet-portrait-up {
                bottom: 50%;
                transform: translate(0, 50%);
                right: 4px;
            }
        }

        .helper-text-container { 
            @include for-tablet-portrait-up {
                padding: 5px 10px 10px 10px; 
                max-height: unset;
                width: 207px;
                left: -51px;
                top: 99px;
            }

            &:after {
                @include for-tablet-portrait-up {
                    right: 10px;
                }
            }
        }

        &.active {
            .helper-text-container {
                max-height: 142px;
    
                @include for-tablet-portrait-up {
                    max-height: auto;
                    left: 0px;
                    top: -88px;
                }
    
                @include for-tablet-landscape-up {
                    left: -3px;
                }
    
                &:after {
                    @include for-tablet-landscape-up {
                        right: 8px;
                    }
                }
            }
        }
    }

    &.address {
        .icon-wrapper {
            img.textarea-icon {
                top: 8px;
            }
        }

        .helper-text-container {
            @include for-tablet-portrait-up {
                width: 207px;
                top: 115px;
                right: 26px;
            }
    
            &:after {
                @include for-tablet-portrait-up {
                    right: 10px;
                }
            }
        }

        &.active {
            .helper-text-container {
                max-height: 58px; 

                @include for-tablet-portrait-up {
                    max-height: unset; 
                }
            }
        }
    }

    &.the-project {
        .icon-wrapper {
            img {
                &.textarea-icon {
                    @include for-tablet-portrait-up {
                        top: 5px;
                    }
                }
            }

            textarea {
                @include for-tablet-portrait-up {
                    padding-top: 5px;
                }
            }
        }

        .helper-text-container {
            width: 180px;
            margin-left: 90px;
            margin-right: auto;


            @include for-iphone678 {
                width: calc(180px + 16vw);
            }

            @include for-iphone678plus {
                width: calc(180px + 20vw);
            }

            @include for-tablet-portrait-up {
                width: 340px;
                top: -61px;
                left: 142px;
                margin-left: unset;
                margin-right: unset;
            }

            &:after {
                @include for-tablet-portrait-up {
                   right: 9px; 
                }
            }
            
            .helper-text {
                text-shadow: 0.1px 0.1px #494949;

                @include for-tablet-portrait-up {
                    text-shadow: none;
                }
            }
        }

        .icon-wrapper {
            width: auto;

            img.textarea-icon {
                top: 5px;
            }
        }

        &.active {
            .helper-text-container {
                max-height: 142px;

                @include for-tablet-portrait-up {
                    max-height: unset;
                }
            }
        }
    }

    &.days-prior-notice {
        @include for-tablet-portrait-up {
            width: auto;
            margin: 0 23px;
            position: relative;
        }

        .icon-wrapper {
            img {
                left: 50px;
                
                @include for-tablet-portrait-up {
                    bottom: 8.5px;
                }
            }

            input {
                @include for-tablet-portrait-up {
                    padding-right: 13px;
                }
            }
        }

        .helper-text-container {
            width: 250px;
            margin-right: auto;

            @include for-tablet-portrait-up {
                width: 240px;
                margin: 0;
                top: -36px;
                left: -169px;
            }
        }

        .helper-text {
            @include for-tablet-portrait-up {
                width: auto;
            }
        }

        &.active {
            .helper-text-container {
                max-height: 100px;
    
                @include for-tablet-portrait-up {
                    max-height: unset;
                }
            }
        }
    }

    &.info-text-area {
        @include for-tablet-portrait-up {
            position: relative; 
        }
        .icon-wrapper {
            width: auto;

            @include for-tablet-portrait-up {
                margin-right: auto;
            }

            img.textarea-icon {
                top: 9px;
            }
        }

        .helper-text-container {
            width: 240px;

            @include for-tablet-portrait-up {
                top: -55px;
                left: 0;
                width: 300px;
            }

            &:after {
                @include for-tablet-portrait-up {
                    right: 9px;
                }
            }
        }

        &.active {
            .helper-text-container {
                max-height: 80px;

                @include for-tablet-portrait-up {
                    max-height: unset;
                }
            }
        }
    }

    &.main-contact {
        .helper-text-container {
            @include for-tablet-portrait-up {
                top: -48px;
                width: 204px;
            }

            &:after {
                @include for-tablet-portrait-up {
                    right: 9px;
                }
            }
        }

        &.signature {
            .helper-text-container {

                @include for-tablet-portrait-up {
                    top: -41px;
                }

                &:after {
                    @include for-tablet-portrait-up {
                        right: 9px;
                    }
                }
            }
        }

        &.active {
            .helper-text-container {
                max-height: 109px;

                @include for-tablet-portrait-up {
                    max-height: 150px;
                }
            }
        }
    }
}

input[type="date"] {
    min-width: 120px;
    // remove if doesn't change on apple
    -webkit-appearance: none;

    @include for-iphone678 {
        font-size: 11px;
        min-width: 125px;
    }
    
    &::-webkit-calendar-picker-indicator {
        background: url('/calendar.png');
        width: 12px;
        height: 12px;
        background-size: 80%;
        background-repeat: no-repeat;
    }
}

// for aligning form items that have an input and label inside. Labels are to the right (on mobile) and have a specific gap between them
.horizontal-align {
    max-width: 100%;
    display: flex;
    flex-direction: row;
    
    input {
        width: 75px;
    }

    label {
        margin: auto 0;
        margin-left: 20px;

        @include for-tablet-portrait-up {
            font-size: 16px;
        }
    }

    &.legal {
        @include for-tablet-portrait-up {
            display: flex;
            align-items: center;
            height: 50px;
            margin-bottom: 45px;
            flex-direction: row;
            justify-content: flex-start;
        }

        input {
            width: 100px;

            @include for-tablet-portrait-up {
                width: 120px;
            }
        }

        &.margin-bottom {
            margin-bottom: 15px;
        }

    }

}

// parties information container (where the two parties are either side by side on desktop or one above each other in mobile)
.part-1, .four, .sign {
    .inner {
        @include for-tablet-portrait-up {
            padding: 0 0 !important;
        }
            
        .parties-info-wrapper {
            z-index: 5;

            @include for-iphone678 {
                display: flex;
                flex-direction: column;
                align-items: center;
                padding-right: 27px;
            }

            // adds orange background behind form items on desktop
            @include for-tablet-portrait-up {
                display: flex;
                flex-direction: column;
                background-color:#FFE8DD;
                align-items: unset;
                padding-right: unset;
            }

            .parties-info {
                display: flex;
                flex-direction: column;
                width: 273px;
                z-index: 2;
                position: relative;
            
                @include for-tablet-portrait-up {
                    width: auto;
                    flex-direction: row;
                }
                    // for all parties
                    .party {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        margin-left: auto;
                        background-color: #FFFF;
                        box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
                        border-radius: 6px;
                        margin-bottom: 6.5vw;
                        width: 232px;
                        padding: 22px 26px;
                        max-height: 1000px;

                        @include for-iphone678 {
                            width: 240px;
                        }
            
                        @include for-tablet-portrait-up {
                            margin-left: unset;
                            width: 256px;
                        }
            
                        // position text before ('us', 'you')
                        &:before {
                            font-family: $header_font_style;
                            font-size: 20px;
                            position: absolute;
                            left: -35px;
                            top: 20px;
                            width: 50px;
                            border-bottom: solid 1px #575757;
                            color: #575757;
            
                            @include for-tablet-portrait-up {
                                left: -40px;
                            }
                        }
            
                        @include for-tablet-portrait-up {
                            .comp-type-nation {
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                                justify-content: space-between;
                                width: 204px;
                                z-index: 0;
            
                                .comp-type {
                                    input {
                                        margin-bottom: 7px;
                                        padding-bottom: 5px;
                                    }
                                }
            
                                .nation {
                                    select {
                                        margin-left: auto;
                                        padding-bottom: 5px;
                                        background-position-y: 56%;
                                    }
                                }
                            }
                        }
                    }
                    
                    .part-a {
                        position: relative;
            
                        @include for-tablet-portrait-up {
                            margin: 0 auto;
                        }
            
                        &:before {
                            content: 'Us:';
                        }
                    }
            
                    .part-b {
                        position: relative;
            
                        @include for-phone-only {
                            margin-bottom: 20px;
                        }
            
                        @include for-tablet-portrait-up {
                            margin-right: auto;
                        }
            
                        &:before {
                            content: 'You:';
                        }
                    }
            }
        }
            
    }    
}

// make the signature section bigger at the bottom for desktop - only uncomment for taking a picture of the pdf
// .sign {
//     @include for-tablet-portrait-up {
//         .parties-info-wrapper {
//             padding: 20px 0;

//             .parties-info .party .signature-container {
//                 margin-bottom: auto;
//             }
//         }
//         .party {
//             padding-bottom: 77px;
//             min-height: 316px;
//         }
//         .circle.large {
//             height: 142px;
//             width: 142px;
//             left: 50%;
//             transform: translate(-50%);
//             bottom: -44px;
//         }
//     }
// }

.form-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 7px;
    width: 100%;

    input, select {
        height: 36px;
        width: 180px;

        @include for-iphone678 {
            width: 100%;
        }

        @include for-tablet-portrait-up {
            height: 40px;
            width: 204px;
        }
    }

    textarea {
        height: 68px;
        width: 180px;

        @include for-iphone678 {
            width: 100%;
        }

        @include for-tablet-portrait-up {
            width: 204px;
        }
    }

    // position x position of dropdown icon. Adjust background-size
    select {
        background-size: 4%;
        background-position-x: 95%;

        @include for-tablet-portrait-up {
            background-size: 9px;
        }
    }

    &.remove-margin {
        margin-bottom: 0 !important; 
    }
}